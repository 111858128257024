import React, { ReactElement } from 'react';
import AsyncPaginate from 'react-select-async-paginate';
import { AppFieldChildrenProps } from '../base';
import { OptionsType, ValueType } from 'react-select';
import { AppFieldSelectOption, ListValueType } from './select';
import { useTranslation } from 'react-i18next';

const defaultCountItems: number = 10;

const AppFieldFactorSelect = ( props: AppFieldChildrenProps<ListValueType> ): ReactElement | null => {
  const { ui, config, field, actions, controlId, readOnlyControlId } = props;
  const { t } = useTranslation( [ 'base' ] );
  const configuration = ui.configuration || {};
  const allOptions: OptionsType<AppFieldSelectOption> = configuration['options'] || [];
  const _selectedOption = allOptions.find( ( i ) => i.value === field.value );
  let selectedOption: AppFieldSelectOption<ListValueType> | null = null;
  if ( _selectedOption !== undefined ) {
    selectedOption = _selectedOption;
  }

  const onChangeHandler = React.useCallback( ( value: ValueType<AppFieldSelectOption> ): void => {
    if ( value ) {
      if ( Array.isArray( value ) ) {
        const values = value.map( ( o: AppFieldSelectOption ) => o.value );
        actions.setFieldValue( field.name, values );
      } else {
        const v = value as AppFieldSelectOption;
        actions.setFieldValue( field.name, v.value );
      }
    }
  }, [ actions, field.name ] );

  const getItemsToFactor = React.useCallback( async ( inputValue ): Promise<AppFieldSelectOption[]> => {
    let items = allOptions as AppFieldSelectOption[];

    if ( inputValue.length > 0 ) {
      const search = inputValue.toLocaleLowerCase();

      items = items.filter( ( o ) => {
        return o.label.toLowerCase().includes ( search );
      } );
    }

    return Promise.resolve( items );
  }, [ allOptions ] );

  const loadOptions = React.useCallback( async ( _search: string, loadedOptions: unknown[] ) => {
    const start = loadedOptions.length;
    const end = start + defaultCountItems;
    const factorOptions = await getItemsToFactor( _search );
    const slicedOptions = factorOptions.slice( start, end );

    return {
      options: slicedOptions,
      hasMore: slicedOptions.length > 0 && end <= allOptions.length ? true : false,
    };
  }, [ allOptions.length, getItemsToFactor ] );

  if ( config.editingDisabled ) {
    // If selected option is not found (since factor values may not be loaded yet) use the field value as label
    return (
      <span id={ readOnlyControlId } className="app-field-plaintext">{ selectedOption?.label ?? field.value }</span>
    );
  }

  return (
    <AsyncPaginate
      debounceTimeout={ 0 }
      id={ controlId }
      className={ `app-field-select state-${ props.state }` }
      classNamePrefix={ 'af-select' }
      isDisabled={ config.editingDisabled || ( configuration['isDisabled'] && configuration['isDisabled'] ) }
      name={ field.name }
      placeholder= { ui.description }
      defaultValue={ selectedOption }
      value={ selectedOption }
      cacheOptions
      loadOptions={ loadOptions }
      blurInputOnSelect={ true }
      isMulti={ false }
      isClearable={ false }
      isSearchable={ true }
      escapeClearsValue={ true }
      menuPlacement="bottom"
      onChange={ onChangeHandler }
      onBlur={ field.onBlur }
      noOptionsMessage={ () => t( 'noOptions' ) }
      loadingMessage={ () => t( 'roles.users.loading' ) }
      maxMenuHeight={ 300 }
    />
  );
};

export default AppFieldFactorSelect;
