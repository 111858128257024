import React, { ReactElement } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FieldInputProps } from 'formik';
import dayjs from 'dayjs';
import { FixDateTime } from '../../Fixes';
import { AppFieldChildrenProps } from '../base';
import { defaultDateFormat } from 'App/ui-utils';

const AppFieldDate = ( props: AppFieldChildrenProps<string> ): ReactElement | null => {
  const { ui, config, meta, field, actions, readOnlyControlId } = props;
  const configuration = ui.configuration || {};

  const generateDateValue = ( stringValue: string ): string => {
    if ( !stringValue ) {
      return '';
    }

    let newDate = stringValue;
    const splitDate = stringValue.split( '.' );

    if( splitDate.length >= 3 ) {
      newDate = stringValue.split( '.' ).reverse().join( '-' );
    }

    const dateVal = new Date( newDate );

    return dayjs( dateVal ).format( defaultDateFormat );
  };

  if ( props.config.editingDisabled ) {
    let dateValue = '';
    if ( dayjs.isDayjs( field.value ) ) {
      dateValue = field.value.format( defaultDateFormat );
    } else {
      dateValue = generateDateValue( field.value );
    }

    return (
      <span id={ readOnlyControlId } className="app-field-plaintext">
        { dateValue }
      </span>
    );
  }

  const handleChangeDate = ( date: string ): void => {
    if ( date !== undefined ) {
      const valueDate: string = dayjs.isDayjs( date ) ?
        date.format( defaultDateFormat ) : dayjs( date ).format( defaultDateFormat ); // Set date format
      actions.setFieldValue( field.name, valueDate );
    }
  };

  const getDefaultValue = ( stringValue: string ): string => {
    if ( stringValue === undefined ) {
      return '';
    }

    if ( dayjs.isDayjs( stringValue ) ) {
      return stringValue.format( defaultDateFormat );
    } else {
      return generateDateValue( stringValue );
    }
  };

  const renderDateInput = (
    inputProps: FieldInputProps<string>,
    openCalendar: () => void,
    closeCalendar: () => void,
  ) => {

    // The className property need to be undefined because correct className is provided by FormControl component
    inputProps['className'] = undefined;

    if ( configuration['isDisabled'] ) {
      return (
        <InputGroup>
          <FormControl
            { ...inputProps }
            isInvalid={ meta.touched && !!meta.error }
          />
        </InputGroup>
      );
    }

    return (
      <InputGroup>
        <FormControl
          { ...inputProps }
          isInvalid={ meta.touched && !!meta.error }
        />
        <InputGroup.Append>
          <InputGroup.Text onClick={ openCalendar } style={ { cursor: 'pointer' } }>
            <i className="fa fa-calendar text-primary" />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    );
  };

  return (
    <FixDateTime
      renderInput={ renderDateInput }
      timeFormat={ false }
      input={ true }
      dateFormat={ defaultDateFormat }
      isValidDate={ ( value ) => configuration.isValidDate ? configuration.isValidDate( value ) : true }
      value={ getDefaultValue( field.value ) }
      className="date-control mt-0"
      closeOnSelect={ true }
      onChange={ ( dateVal ) => { handleChangeDate( dateVal as string ); } }
      onBlur={ ( ) => actions.setFieldTouched( field.name ) }
      inputProps={ {
        name: field.name,
        placeholder: ui.description,
        disabled: config.editingDisabled || ( configuration['isDisabled'] && configuration['isDisabled'] ),
        readOnly: true,
      } }
    />
  );
};

export default AppFieldDate;
