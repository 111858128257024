import { ComponentClass, FunctionComponent, ForwardRefExoticComponent } from 'react';
import { FormikProps, FormikValues, FormikConfig, FieldMetaProps, FieldInputProps } from 'formik';
import { isObject, isString } from 'types';
import { Namespace } from 'react-i18next';
import { Modify } from 'App/utils';
import * as yup from 'yup';

import text from './field-types/text';
import textArea from './field-types/text-area';
import number from './field-types/number';
import boolean from './field-types/boolean';
import date from './field-types/date';
import colorPicker from './field-types/color-picker';
import colorInput from './field-types/color-input';
import select from './field-types/select';
import asyncSelect from './field-types/async-select';
import factorSelect from './field-types/factor-select';
import selectGender from './field-subtypes/select_gender';
import imageResizer from './field-types/image-resizer';
import password from './field-types/password';

export interface AppBaseFormProps<Values> {
  initialValue: Values;
  /**
    * Reset handler
    */
  onReset?: ( values: Values, actions: AppFormikHelpers<Values> ) => void;
  /**
    * Submission handler. Handler should return resolved promise after successful handling submition
    */
  onSubmit: ( values: Values, actions: AppFormikHelpers<Values> ) => Promise<void>;
}

export type FormikFieldStateType = 'new' | 'changed' | 'valid' | 'invalid';

export type FormikFieldState<Values> = {
  [K in keyof Values]: FormikFieldStateType;
};

export type FormikChanged<Values> = {
  [K in keyof Values]: boolean;
};

export type AppFormikErrors<Values> = {
  [K in keyof Values]?: Values[K] extends any[]
    ? Values[K][number] extends object
      ? AppFormikErrors<Values[K][number]>[] | IValidationError | IValidationError[]
      : IValidationError | IValidationError[]
    : Values[K] extends object
      ? AppFormikErrors<Values[K]>
      : IValidationError | IValidationError[];
};

export type AppFormikErrorType = 'AppFormikError';

export interface AppFormikError<Values> {
  type: AppFormikErrorType;
  errors: AppFormikErrors<Values>;
}

const appFormikErrorTypeId: AppFormikErrorType = 'AppFormikError';

export const createAppFormikError = <Values>( errors: AppFormikErrors<Values> ): AppFormikError<Values> => {
  return {
    type: appFormikErrorTypeId,
    errors: errors,
  };
};

export const isAppFormikError = <Values>( obj: any ): obj is AppFormikError<Values> =>
  isObject( obj ) && obj.type !== undefined && obj.type === appFormikErrorTypeId;

export interface AppFormActions {
  setEditable: ( editable: boolean ) => void;
}

export interface AppFormChildrenProps {
  editable: boolean;
  formId: string;
  actions: AppFormActions;
}

export interface AppFormikHelpers<Values> extends Modify<FormikProps<Values>, {
  /** Manually set errors in AppFormik way */
  setErrors( errors: AppFormikErrors<Values> ): void;
}> {}

export interface AppFormProps {
  withEditSwitch?: boolean;
  withOutlinedSwitchIcon?: boolean;
  disableSwitchDuringEditing?: boolean;
  initiallyEditable?: boolean;
  formId: string;
  title: string;
  showTitle?: boolean;
  children: ( ( props: AppFormChildrenProps ) => React.ReactNode );
}

interface AppFormikChildrenExtraProps<Values> {
  changed: FormikChanged<Values>;
  fieldState: FormikFieldState<Values>;
  config: ModifiedFormikConfig<Values> & Required<AppFormikProps>;
  actions: AppFormikHelpers<Values>;
}

interface ModifiedFormikProps<Values> extends Modify<FormikProps<Values>, {
  errors: AppFormikErrors<Values>;
}> {}

export type AppFormikChildrenPropsType<Values> = ModifiedFormikProps<Values> & AppFormikChildrenExtraProps<Values>;

export type AppFormikActionType = 'FORM_ERROR' | 'FIELD_CHANGED' | 'SET_FIELD_TOUCHED' | 'FORM_RESET';

interface FormActionPayload {
  formId: string;
}

export interface FormErrorActionPayload<V extends FormikValues = FormikValues> extends FormActionPayload {
  errors: AppFormikErrors<V>;
}

export interface FormResetActionPayload<V extends FormikValues = FormikValues> extends FormActionPayload {
  formValues: V;
}

export interface FieldChangedActionPayload<V = any> extends FormActionPayload {
  meta: AppFieldMetaProps;
  fieldValue: any;
  initialValue: any;
  formValues: V;
}

export interface SetFieldTouchedActionPayload extends FormActionPayload {
  meta: AppFieldMetaProps;
  touched: boolean;
}

export interface AppFormikProps {
  formId: string;
  trackingChanges?: boolean;
  editingDisabled?: boolean; // false by default
  labelGridSize?: number;
}

// This type redeclare children and onSubmit property/methods in FormikConfig
export type ModifiedFormikConfig<Values> = Modify<FormikConfig<Values>, {
  children: ( ( props: AppFormikChildrenPropsType<Values> ) => React.ReactNode );
  onSubmit?: ( values: Values, formikHelpers: AppFormikHelpers<Values> ) => Promise<any>;
  onReset?: ( values: Values, formikHelpers: AppFormikHelpers<Values> ) => void;
  referenceValues?: Values;
  detectChangeOnFields?: string[];
}>;

export type AppFormikPropsType<Values extends FormikValues = FormikValues>
  = ModifiedFormikConfig<Values> & AppFormikProps;

type AppFormikFieldType =
ComponentClass<AppFieldChildrenProps<any, any>, any>|
FunctionComponent<AppFieldChildrenProps<any, any>> |
ForwardRefExoticComponent<any> | undefined;

export const AppFormikFieldTypes:
Record<string, AppFormikFieldType> =
  {
    'text':  text,
    'text-area':  textArea,
    'number':  number,
    'boolean':  boolean,
    'date':  date,
    'color-picker':  colorPicker,
    'color-input':  colorInput,
    'select':  select,
    'async-select':  asyncSelect,
    'factor-select':  factorSelect,
    'select_gender':  selectGender,
    'image-resizer':  imageResizer,
    'password':  password,
  };

export type AppFieldType = 'text' | 'text-area' |
'boolean' | 'date' | 'password' | 'number' | 'color-picker' | 'select_gender' |
'color-input' | 'select' | 'image-resizer' | 'async-select' | 'factor-select' | 'duration-unit';
export type AppFieldSubType = 'gender' | 'iban' | 'phone' | 'email' | 'payment-method';

export interface AppFieldMetaProps {
  title: string;
  required?: boolean;
  fieldName: string;
  description?: string;
  fieldCopy?: string;
  fieldType: AppFieldType;
  businessType?: AppFieldSubType;
  minValue?: number;
  maxValue?: number;
  hint?: string;
  rowClass?: string;
  labelClass?: string;
  toStringCallback?: ( ( value: string | number ) => string );
  /**
   * Additional Dictionary of props to initialize UI component
   * For example options for select fieldType.
   */
  configuration?: AppFieldConfiguration;
}

export interface AppFieldConfiguration {
  [key: string]: any;
}

export const generateSchema = <Model extends object>( fields: AppFieldMetaProps[] ): yup.ObjectSchema<Model> => {
  const shapeObject: object = {};
  for ( let field of fields ) {
    shapeObject[field.fieldName] = validationRulesForField( field );
  }
  return yup.object<Model>( shapeObject as yup.ObjectSchemaDefinition<Model> ).defined();
};

export const phoneValidationRegExp: RegExp = /^\+[0-9]{9,16}$/;

export const validationRulesForField = ( field: AppFieldMetaProps ): yup.Schema<any> => {
  if ( field.fieldType === 'text' || field.fieldType === 'date' ||
  field.fieldType === 'text-area' || field.fieldType === 'color-input' ||
  field.fieldType === 'select' ) {
    let rule: yup.StringSchema = yup.string();
    if ( field.required ) {
      rule = rule.required( 'messages.fieldRequired' );
    }
    if ( field.businessType === 'email' ) {
      rule = rule.email( 'messages.invalidEmail' );
    }
    if ( field.businessType === 'phone' ) {
      rule = rule.isPhoneNumber( 'messages.invalidPhoneFormat' );
    }
    return rule;
  }
  if ( field.fieldType === 'number' ) {
    let rule: yup.NumberSchema = yup.number();
    if ( field.required ) {
      rule = rule.required( 'messages.fieldRequired' );
    }
    if ( field.maxValue ) {
      rule = rule.max( field.maxValue!, 'messages.numberMax' );
    }
    if ( field.minValue ) {
      rule = rule.min( field.minValue!, 'messages.numberMin' );
    }
    return rule;
  }
  if ( field.fieldType === 'boolean' ) {
    let rule: yup.BooleanSchema = yup.boolean();
    if ( field.required ) {
      rule = rule.required( 'messages.fieldRequired' );
      // assume that true value is required here
      rule = rule.oneOf( [ true ], 'messages.booleanTrueRequired' );
    }
    return rule;
  }

  // in other case just return string schema without any validation rules applied
  return yup.string().required();
};

export interface AppFieldChildrenProps<V = any, FormValues = any> {
  field: FieldInputProps<V>;
  config: ModifiedFormikConfig<FormValues> & Required<AppFormikProps>;
  ui: AppFieldMetaProps;
  meta: FieldMetaProps<V>;
  changed: boolean;
  state: FormikFieldStateType;
  actions: AppFormikHelpers<V>;
  controlId: string;
  readOnlyControlId: string;
}

export type AppFieldRenderType = 'horizontal' | 'forced-horizontal' | 'vertical';

export interface AppFieldProps<V = any> {
  renderAs?:
  | React.ComponentType<AppFieldChildrenProps<V>>
  | React.ForwardRefExoticComponent<AppFieldChildrenProps<V>>;

  children?: ( ( props: AppFieldChildrenProps<V> ) => React.ReactNode ) | React.ReactNode;
  /**
   * How Field should be rendered. Possible options are 'horizontal' and 'vertical'. Default: 'horizontal'
   */
  renderType?: AppFieldRenderType;
}

export type AppFieldPropsType = AppFieldMetaProps & AppFieldProps;

export interface ErrorMessageProps
<I extends IValidationError | IValidationError[] = IValidationError | IValidationError[]> {
  error: I;
  meta: AppFieldPropsType;
}

export interface ValidationSchemaOptions {
  showMultipleFieldErrors?: boolean;
}

export const formsTranslationNamespace: Namespace = [ 'forms' ];

export interface IValidationError {
  /**
   * Error message from Yup. Errors with prefix 't:' will be translated during rendering phase
   * Translation messages can use parameters with prefix ui. and context.
   * ui. - is the metadata from field definition
   * context. - is the data from yup that was during validation
   *
   * For example You can provide such message:
   * Field {{ui.title}} have value {{context.value}} but this value should have more than {{context.min}} characters
   */
  message: string;
  /**
   * Validation context at the time when error was generated by Yup.
   * Context can be used later for translations
   */
  context?: object;
}

export const isValidationError = ( obj: any ): obj is IValidationError =>
  isObject( obj ) && isString( obj.message );

