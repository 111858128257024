import React, { ReactElement, ReactNode } from 'react';

import {
  AppFieldPropsType,
  AppFieldChildrenProps,
  AppFieldRenderType,
  IValidationError,
  AppFieldMetaProps,
  AppFormikHelpers,
  SetFieldTouchedActionPayload,
  FieldChangedActionPayload,
  AppFormikFieldTypes,
} from './base';
import { Field, FieldProps, FieldInputProps, getIn } from 'formik';
import { Row, Col, Form, ColProps } from 'react-bootstrap';
import { useAppFormikContext } from './AppFormikContext';
import { ErrorMessages } from './AppFormikError';
import { isFunction, AppError } from 'App/utils';
import { useAppPageContext } from '../pages/context';
import { useEventCallback, isString, AppReducerAction } from 'types';
import { getValueForCheckbox, getSelectedValues } from './helper';
import classNames from 'classnames';

const renderingHints: Record<string, AppFieldRenderType> = {
  'boolean': 'forced-horizontal',
};

export const AppField = ( appFieldProps: AppFieldPropsType ): ReactElement | null => {

  const {
    children,
    renderAs,
    description: placeholder,
    ...props
  } = appFieldProps;

  const pageCtx = useAppPageContext();
  const context = useAppFormikContext();
  const fieldState = context.fieldState[appFieldProps.fieldName];
  const fieldChanged = context.changed[appFieldProps.fieldName];
  const formConfig = context.config;
  const formikActions = context.actions;

  const renderType: AppFieldRenderType = appFieldProps.renderType
    || renderingHints[appFieldProps.fieldType]
    || 'horizontal';

  const lgs = formConfig.labelGridSize;
  const cgs = 12 - lgs;

  const isHorizontal: boolean = renderType === 'horizontal';
  let labelColProps: ColProps = isHorizontal ? { md: lgs } : { md: 12 };
  let controlColProps: ColProps = isHorizontal ? { md: cgs } : { md: 12 };
  let errorAndHintColProps: ColProps = isHorizontal ? { md: { span: cgs, offset: lgs } } : { md: 12 };

  if ( renderType === 'forced-horizontal' ) {
    labelColProps = { xs: lgs };
    controlColProps = { xs: cgs };
    errorAndHintColProps = { xs: { span: cgs, offset: lgs } };
  }

  const setFieldTouched = useEventCallback(
    ( field: string, touched: boolean = true, shouldValidate?: boolean ) => {
      formikActions.setFieldTouched( field, touched );
      const actionPayload: SetFieldTouchedActionPayload = {
        formId: context.config.formId,
        meta: props,
        touched: touched,
      };
      pageCtx.fireAction( { type: 'SET_FIELD_TOUCHED', payload: actionPayload } );
    },
  );

  const executeBlur = React.useCallback(
    ( e: any, path?: string ) => {
      if ( e.persist ) {
        e.persist();
      }
      const { name, id } = e.target;
      const field = path ? path : name ? name : id;
      setFieldTouched( field, true ); // tell original Formik to change its state
    },
    [ setFieldTouched ],
  );

  const handleBlur = useEventCallback( ( eventOrString: any ):
  | void
  | ( ( e: any ) => void ) => {
    if ( isString( eventOrString ) ) {
      return ( event ) => executeBlur( event, eventOrString );
    } else {
      executeBlur( eventOrString );
    }
  } );

  const setFieldValue = useEventCallback(
    ( field: string, value: any, shouldValidate?: boolean ) => {
      formikActions.setFieldValue( field, value, shouldValidate );
      const actionPayload: FieldChangedActionPayload = {
        formId: context.config.formId,
        meta: props,
        fieldValue: value,
        initialValue: context.initialValues[field],
        formValues: { ...context.values, [field]: value },
      };
      const action: AppReducerAction = { type: 'FIELD_CHANGED', payload: actionPayload };
      pageCtx.fireAction( action );
    },
  );

  const executeChange = React.useCallback(
    ( eventOrTextValue: string | React.ChangeEvent<any>, maybePath?: string ) => {
      // By default, assume that the first argument is a string. This allows us to use
      // handleChange with React Native and React Native Web's onChangeText prop which
      // provides just the value of the input.
      let field = maybePath;
      let val = eventOrTextValue;
      let parsed;
      // If the first argument is not a string though, it has to be a synthetic React Event (or a fake one),
      // so we handle like we would a normal HTML change event.
      if ( !isString( eventOrTextValue ) ) {
        // If we can, persist the event
        // @see https://reactjs.org/docs/events.html#event-pooling
        if ( ( eventOrTextValue as React.ChangeEvent<any> ).persist ) {
          ( eventOrTextValue as React.ChangeEvent<any> ).persist();
        }
        const target = eventOrTextValue.target
          ? ( eventOrTextValue as React.ChangeEvent<any> ).target
          : ( eventOrTextValue as React.ChangeEvent<any> ).currentTarget;

        const {
          type,
          name,
          id,
          value,
          checked,
          options,
          multiple,
        } = target;

        field = maybePath ? maybePath : name ? name : id;
        val = /number|range/.test( type )
          ? ( ( parsed = parseFloat( value ) ), isNaN( parsed ) ? '' : parsed )
          : /checkbox/.test( type ) // checkboxes
            ? getValueForCheckbox( getIn( context.values, field! ), checked, value )
            : !!multiple // <select multiple>
              ? getSelectedValues( options )
              : value;
      }

      if ( field ) {
        // Set form fields by name
        setFieldValue( field, val );
      }
    },
    [ context.values, setFieldValue ],
  );

  const handleChange = useEventCallback(
    (
      eventOrPath: string | React.ChangeEvent<any>,
    ): void | ( ( eventOrTextValue: string | React.ChangeEvent<any> ) => void ) => {
      if ( isString( eventOrPath ) ) {
        return ( event ) => executeChange( event, eventOrPath );
      } else {
        executeChange( eventOrPath );
      }
    },
  );

  const actions: AppFormikHelpers<any> = {
    ...formikActions,
    setFieldTouched: setFieldTouched,
    setFieldValue: setFieldValue,
  };

  return (
    <Field name={ appFieldProps.fieldName } >
      { ( fieldProps: FieldProps ) => {
        const { meta } = fieldProps;
        const uiProps: AppFieldMetaProps = {
          ...props,
          description: placeholder !== undefined ? placeholder : props.title,
        };
        const controlIdSuffix = `${formConfig.formId}-${appFieldProps.fieldName}-cid`;
        const controlId = `app-field-${controlIdSuffix}`;
        const readOnlyControlId = `app-readonly-field-${controlIdSuffix}`;

        const fieldPropsWrapper: FieldInputProps<any> = {
          ...fieldProps.field,
          onChange: handleChange,
          onBlur: handleBlur,
        };

        const childrenProps: AppFieldChildrenProps = {
          field: fieldPropsWrapper,
          config: formConfig,
          ui: uiProps,
          state: fieldState,
          changed: fieldChanged,
          meta: meta,
          actions: actions,
          controlId: controlId,
          readOnlyControlId: readOnlyControlId,
        };

        // Create and Translate error message only if isInvalid is true
        const validationErrors = meta.error as unknown as IValidationError | IValidationError[] | undefined;
        const isInvalid: boolean = meta.touched && validationErrors !== undefined;

        let componentToRender: ReactNode = null;
        if ( isFunction( appFieldProps.children ) ) {
          componentToRender = appFieldProps.children( childrenProps );
        } else {
          if ( renderAs ) {
            componentToRender = React.createElement( renderAs, childrenProps, children );
          } else {
            let fieldType: string = uiProps.fieldType;
            if ( uiProps.businessType ) {
              fieldType = fieldType + '_' + uiProps.businessType;
            }
            let componentClass = AppFormikFieldTypes[fieldType];
            if ( componentClass === undefined ) {
              componentClass = AppFormikFieldTypes[uiProps.fieldType];
            }
            if ( componentClass === undefined ) {
              const e: AppError = {
                name: 'AppFieldError',
                message: 'Can\'t determine component to render AppField',
                context: appFieldProps,
              };
              throw e;
            }
            componentToRender = React.createElement( componentClass, childrenProps, children );
          }
        }

        return (
          <Form.Group
            className={ classNames(
              `align-items-center app-field app-field-state-${fieldState}`,
              appFieldProps.rowClass || '',
            ) }
            key={ `form-group-${appFieldProps.fieldName}-key` }
            controlId={ controlId }
            as={ Row }
          >
            <Form.Label
              column { ...labelColProps }
              className={ appFieldProps.labelClass ? `app-field-label ${appFieldProps.labelClass}` : 'app-field-label' }
            >
              { appFieldProps.title }
              { appFieldProps.fieldCopy &&
              <div className="mt-2 text-muted"> { appFieldProps.fieldCopy }</div>
              }
            </Form.Label>
            <Col { ...controlColProps } className="app-field-control">
              { componentToRender }
            </Col>
            { !formConfig.editingDisabled && appFieldProps.hint && (
              <Col { ...errorAndHintColProps } className='form-text small'>{ appFieldProps.hint }</Col>
            ) }
            { isInvalid && (
              <Col { ...errorAndHintColProps }>
                <ErrorMessages error={ validationErrors! } meta={ appFieldProps } />
              </Col>
            ) }
          </Form.Group>
        ); } }
    </Field>
  );
};
