import React from 'react';
import { toSelectorOptions, AppFieldSelectOption } from 'App/components/utils/forms/field-types/select';
import { useFormTranslation } from 'App/components/utils/forms/hooks';
import { useCurrencySymbol } from 'App/components/utils/hooks';
import { useTranslation } from 'react-i18next';

export interface SelectorOptionsType {
  items: AppFieldSelectOption<string|number>[];
  toStringCallback: ToStringCallbackType;
}

export const useSelectorOptions = ( dictKey: string, values: string[] ): SelectorOptionsType => {
  const t = useFormTranslation();
  const dict = t( dictKey, { returnObjects: true } ) as Record<string|number, string>;
  const toStringCallback = generateToStringCallback( dict );
  const options = React.useMemo( () => {
    return toSelectorOptions( values, dict );
  }, [ dict, values ] );
  return { items: options, toStringCallback: toStringCallback };
};

export const policyStatusValues: string[] = [
  'applied', 'active', 'performanceFree', 'pause', 'cancel',
];
export const usePolicyStatusSelectorOptions = () => {
  return useSelectorOptions( 'policyStatusDict', policyStatusValues );
};

export const paymentMethodValues: string[] = [
  'directDebit', 'bankTransfer',
];
export const usePaymentMethodSelectorOptions = () => {
  return useSelectorOptions( 'paymentMethodDict', paymentMethodValues );
};

export const paymentFrequencyValues: string[] = [
  'monthly', 'quarterly', 'halfYearly', 'yearly',
];
export const usePaymentFrequencySelectorOptions = () => {
  return useSelectorOptions( 'paymentFrequencyDict', paymentFrequencyValues );
};

export const policyContractLengthValues: string[] = [
  'unlimited', '1y', '3y', '5y',
];
export const usePolicyContractLengthSelectorOptions = () => {
  return useSelectorOptions( 'policyContractLengthDict', policyContractLengthValues );
};

export const familyInfoValues: string[] = [
  'single', 'familyWithoutKids', 'singleWithKids', 'familyWithKids',
];
export const useFamilyInfoSelectorOptions = () => {
  return useSelectorOptions( 'familyInfoDict', familyInfoValues );
};

export const genderValues: string[] = [
  'male', 'female', 'unspecified',
];
export const useGenderSelectorOptions = () => {
  return useSelectorOptions( 'genderDict', genderValues );
};

export const liabilityGroupValues: string[] = [
  '1', '2', '3', '4', '5+',
];
export const useLiabilityGroupSelectorOptions = (): SelectorOptionsType => {
  const dict = React.useMemo( () => {
    const r: Record<string, string> = {};
    liabilityGroupValues.forEach( ( val ) => {
      r[val] = val;
    } );
    return r;
  }, [] );

  const result = React.useMemo<SelectorOptionsType>( () => {
    const callback = generateToStringCallback( dict );
    return {
      items: toSelectorOptions( liabilityGroupValues, dict ),
      toStringCallback: callback,
    };
  }, [ dict ] );
  return result;
};

export const useDeductableSelectorOptions = (): SelectorOptionsType => {
  const values: string[] = [ '0', '150', '300', '500' ];
  const toStringCallback = useDeductableToStringCallback();
  const dict = React.useMemo( () => {
    const r: Record<string, string> = {};
    values.forEach( ( val ) => {
      r[val] = toStringCallback( val );
    } );
    return r;
  }, [ toStringCallback, values ] );
  const result = React.useMemo<SelectorOptionsType>( () => {
    return {
      items: toSelectorOptions( values, dict ),
      toStringCallback: toStringCallback,
    };
  }, [ dict, toStringCallback, values ] );
  return result;
};

export const tariffValues: string[] = [
  'light', 'basis', 'premium',
];
export const useTariffSelectorOptions = () => {
  return useSelectorOptions( 'tariffDict', tariffValues );
};
export const useTariffToStringCallback = () => {
  return useToStringCallback( 'tariffDict' );
};
export const useToStringCallback = ( dictKey: string ): ToStringCallbackType => {
  const t = useFormTranslation();
  const callback = React.useMemo<ToStringCallbackType>( () => {
    const dict = t( dictKey, { returnObjects: true } ) as Record<string|number, string>;
    return generateToStringCallback( dict );
  }, [ dictKey, t ] );
  return callback;
};
export const generateToStringCallback = ( dict: Record<string|number, string> ): ToStringCallbackType => {
  const cb: ToStringCallbackType = ( value ) => {
    return dict[value];
  };
  return cb;
};

export type ToStringCallbackType = ( value: string|number ) => string;


export const useDeductableToStringCallback = (): ToStringCallbackType => {
  const t = useFormTranslation();
  const symbol = useCurrencySymbol();
  const callback = ( value: string | number ): string => {
    return t( 'deductableOption', { amount: value, symbol: symbol } );
  };
  return callback;
};

export const useTourData = () => {
  const { t } = useTranslation( [ 'tour' ] );
  const tourData = React.useMemo( () => {
    const data = {
      title: t( 'tour.title' ),
      description: t( 'tour.description' ),
    };
    return data;
  }, [ t ] );
  return tourData;
};
