import React from 'react';
import { IIdentityTrackableEntity } from 'Services/base/interfaces';
import { FiltersPage, PagingRequest } from 'Services/base';
import { IUserDetailsItem } from 'Services/users/user-interfaces';
import { useAppUser } from '../../../components/utils/providers/AppUserProvider';

export interface IEntityInfoHook {
  getSystemUsers(): IUserDetailsItem[];
  getFullUserName( user?: IUserDetailsItem ): string;
  getQueryParams( entities: IIdentityTrackableEntity[] ): PagingRequest;
  mapUsersToEntities( entities: IIdentityTrackableEntity[], users?: IUserDetailsItem[] ): void;
  getLatestEntity ( entities?: IIdentityTrackableEntity[] ): IIdentityTrackableEntity|undefined;
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export const useEntityInfoHook = (): IEntityInfoHook => {
  const appUser = useAppUser();

  const sortEntities = React.useCallback( (
    entities?: IIdentityTrackableEntity[], sortDirection = SortDirection.DESC,
  ): IIdentityTrackableEntity[] => {
    const DATETIME_MIN = -8640000000000000;
    return entities?.sort( ( ( a: IIdentityTrackableEntity, b: IIdentityTrackableEntity ): number => {
      const aVal = ( a.updatedAt || a.createdAt ) as string || DATETIME_MIN;
      const bVal = ( b.updatedAt || b.createdAt ) as string || DATETIME_MIN;
      const aTime = new Date( aVal ).getTime();
      const bTime = new Date( bVal ).getTime();

      return sortDirection === SortDirection.DESC ? bTime - aTime : aTime - bTime;
    } ) ) || [];
  }, [] );

  const getLatestEntity = React.useCallback(
    ( entities?: IIdentityTrackableEntity[] ): IIdentityTrackableEntity|undefined => {
      const sorted = sortEntities( entities, SortDirection.DESC );

      return sorted.find( ( p ) => p );
    }, [ sortEntities ] );

  const getSystemUsers = React.useCallback( () => {
    const { sub, userFullName, userName, tokenMeta } = appUser;
    return [ {
      sub: sub,
      email: tokenMeta?.email || '',
      firstName: userFullName || userName,
      lastName: '',
    },
    {
      sub: 'Emil System',
      email: 'kontakt@emil.de',
      firstName: 'Emil System',
      lastName: '',
      organization: {
        name: 'EMIL Group GmbH',
      },
    } ] as IUserDetailsItem[];
  }, [ appUser ] );

  const getFullUserName = React.useCallback( ( user?: IUserDetailsItem ): string => {
    if ( user ) {
      const parts = [ user.firstName || '', user.lastName || '' ];
      return parts.join( ' ' ) || '';
    }
    return '';
  }, [] );

  const utils = React.useMemo<IEntityInfoHook>( () => {
    return {
      getQueryParams: ( entities: IIdentityTrackableEntity[] ): PagingRequest => {
        const systemUsers = getSystemUsers();
        const subList = entities.filter( ( p )=> !!p )
          .map( ( p ) => p.updatedBy || p.createdBy )
          .filter( ( p ) => !!p && !!!systemUsers.find( ( su ) => su.sub === p ) );
        const uniqueSubs = [ ...new Set( subList ) ];
        const filters = uniqueSubs.map( ( p ) => {
          const filter: FiltersPage = { id: 'sub', value: p!, operator: '==' };

          return filter;
        } );
        const queryParams: PagingRequest = {
          filters: filters,
          pageSize: filters.length,
          pageToken: 0,
          filter: [],
          orderBy: [],
        };
        return queryParams;
      },
      mapUsersToEntities: ( entities?: IIdentityTrackableEntity[], users?: IUserDetailsItem[] ): void => {
        if ( entities && entities.length ) {
          const userList = getSystemUsers();
          userList.push( ...( users || [] ) );
          entities?.forEach( ( p ) => {
            if ( p ) {
              p.cashedUser = userList?.find( ( u ) => u.sub === p.updatedBy || u.sub === p.createdBy );
            }
          } );
        }
      },
      getSystemUsers: (): IUserDetailsItem[] =>{
        return getSystemUsers();
      },
      getFullUserName: ( user?: IUserDetailsItem ): string => {
        return getFullUserName( user );
      },
      getLatestEntity: ( entities?: IIdentityTrackableEntity[] ): IIdentityTrackableEntity|undefined => {
        return getLatestEntity( entities );
      },
    };
  }, [ getFullUserName, getLatestEntity, getSystemUsers ] );

  return utils;
};
