import React, { ReactElement, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { AppFieldChildrenProps } from '../base';
import reactCSS from 'reactcss';
import { SketchPicker, ColorResult } from 'react-color';

const AppFieldColor = ( props: AppFieldChildrenProps<string> ): ReactElement | null => {
  const { ui, config, meta, field, actions, readOnlyControlId } = props;
  const [ showColorPicker, setShowColorPicker ] = React.useState( false );
  const colorPickerStyles = React.useMemo( () => {
    const styles = reactCSS( {
      'default': {
        popover: {
          position: 'absolute',
          top: '43px',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    } );

    return styles;
  }, [] );

  if ( config.editingDisabled ) {
    return (
      <span id={ readOnlyControlId } className="app-field-plaintext">
        { field.value }
      </span>
    );
  }

  const onChangeHandler = ( color: ColorResult ): void => {
    actions.setFieldValue( field.name, color.hex );
  };

  let colorClassName : string = 'color-input-icon no-color-icon';

  if ( field.value && field.value !== 'none' ) {
    colorClassName = 'color-input-icon';
  }

  return (
    <Fragment>
      <span
        className={ colorClassName }
        style={ field.value ? { background: field.value } : { background: '#ffffff' } }
      >
      </span>
      <Form.Control
        className="pl-5"
        type="text"
        placeholder={ ui.description }
        plaintext={ config.editingDisabled }
        readOnly={ config.editingDisabled }
        onClick={ () => setShowColorPicker( true ) }
        isInvalid={ meta.touched && !!meta.error }
        { ...field }
      />
      { showColorPicker && (
        <div style={ colorPickerStyles.popover }>
          <div style={ colorPickerStyles.cover } onClick={ () => setShowColorPicker( false ) } />
          <SketchPicker color={ field.value } onChange={ onChangeHandler } />
        </div>
      ) }
    </Fragment>
  );
};

export default AppFieldColor;
