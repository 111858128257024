import React, { ReactElement, Fragment, useRef } from 'react';
import { AppFieldChildrenProps } from '../base';
import Resizer from 'react-image-file-resizer';
import { Button } from 'react-bootstrap';

const AppFieldImageResizer = ( props: AppFieldChildrenProps<string> ): ReactElement | null => {
  const { ui, config, field, actions, readOnlyControlId } = props;
  const configuration = ui.configuration || {};

  if ( config.editingDisabled ) {
    return (
      <div
        id={ readOnlyControlId }
        className="img-placeholder float-left d-inline-flex align-items-center"
      >
        <img className="rounded mx-auto d-block" src={ props.field.value } alt={ ui.description } />
      </div>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputFile = useRef<HTMLInputElement>( null );

  const onChangeHandler = ( event ): void => {
    if( event.target.files[0] ) {
      Resizer.imageFileResizer(
        event.target.files[0],
        configuration.maxWidth,
        configuration.maxHeight,
        configuration.compressFormat,
        configuration.quality,
        configuration.rotation,
        ( uri ) => {
          actions.setFieldValue( field.name, uri );
        },
        'base64',
      );
    }
  };

  const onBlurHandler = (): void => {
    actions.setFieldTouched( field.name );
  };

  const onOpenUploadWindow = ( e ) => {
    e.stopPropagation();
    e.preventDefault();
    // `current` points to the mounted file input element
    if ( inputFile !== null ) {
      inputFile.current?.click();
    }
  };

  // When using custom widgets that don't have its own input field with name
  // Custom widget also don't generate onBlur event
  return (
    <Fragment>
      <div className="img-placeholder form-control-img float-left d-inline-flex align-items-center"
        tabIndex={ -1 } onBlur={ onBlurHandler }
      >
        <input type="hidden" name={ field.name } value={ field.value } />
        <input hidden={ true } type="file" onChange={ onChangeHandler }
          ref={ inputFile } style={ { display: 'none' } }
        />
        <img className="rounded mx-auto d-block" src={ props.field.value } alt={ ui.description } />
      </div>
      <Button className="mr-4 mb-0 p-0 btn-user-nav" type="button" variant="link"
        onClick={ onOpenUploadWindow } title={ ui.description }
      >
        { ui.description }
      </Button>
    </Fragment>
  );
};

export default AppFieldImageResizer;
