import { IAppPermissionDict, IAppPermissionDictOld } from './interfaces';

const permissionDictOld: IAppPermissionDictOld = {
  ts_All: 'ts:*',
  ts_ListTickets: 'ts:ListTickets',
  is_All: 'is:*',
  is_ListPolicies: 'is:ListPolicies',
  is_GetPolicy: 'is:GetPolicy',
  is_CreatePolicy: 'is:CreatePolicy',
  is_ListVwbInbox: 'is:ListVwb',
  as_All: 'as:*',
  as_ListAccounts: 'as:ListAccounts',
  as_GetAccount: 'as:GetAccount',
  pb_All: 'pb:*',
  pb_ListDashboards: 'pb:ListDashboards',
  tnts_All: 'tnts:*',
  tnts_ListSettings: 'tnts:ListSettings',
  ds_All: 'ds:*',
};

export const permissionDict: IAppPermissionDict = {
  ...permissionDictOld,
  admin: '*.*',
  pbm_all: 'pbm.*',
  pbm_dashboard_all: 'pbm.dashboard.*',
  pbm_dashboard_view: 'pbm.dashboard.view',
  pbm_dashboard_write: 'pbm.dashboard.write',
  pbm_dashboard_delete: 'pbm.dashboard.delete',
  pbm_alerts_all: 'pbm.alerts.*',
  pbm_alerts_view: 'pbm.alerts.view',
  pbm_alerts_write: 'pbm.alerts.write',
  pbm_alerts_delete: 'pbm.alerts.delete',
  pbm_reports_all: 'pbm.reports.*',
  pbm_reports_view: 'pbm.reports.view',
  pbm_reports_write: 'pbm.reports.write',
  pbm_reports_delete: 'pbm.reports.delete',
  pbm_tc_all: 'pbm.tariffcomparisons.*',
  pbm_tc_view: 'pbm.tariffcomparisons.view',
  pbm_tc_write: 'pbm.tariffcomparisons.write',
  pbm_tc_delete: 'pbm.tariffcomparisons.delete',
  pbm_sa_all: 'pbm.scenarioanalyses.*',
  pbm_sa_view: 'pbm.scenarioanalyses.view',
  pbm_sa_write: 'pbm.scenarioanalyses.write',
  pbm_sa_delete: 'pbm.scenarioanalyses.delete',
  tenants_all: 'tenants.*',
  tenants_settings_all: 'tenants.settings.*',
  tenants_settings_view: 'tenants.settings.view',
  tenants_settings_write: 'tenants.settings.write',
  tenants_settings_delete: 'tenants.settings.delete',
  tenants_users_all: 'tenants.users.*',
  tenants_users_view: 'tenants.users.view',
  tenants_users_write: 'tenants.users.write',
  tenants_users_delete: 'tenants.users.delete',
  tenants_roles_all: 'tenants.roles.*',
  tenants_roles_view: 'tenants.roles.view',
  tenants_roles_write: 'tenants.roles.write',
  tenants_roles_delete: 'tenants.roles.delete',
  eis_all: 'eis.*',
  eis_home_page: 'eis.homepage.*',
  eis_finance: 'eis.finance.*',
  eis_finance_invoices: 'eis.finance.invoices',
  eis_policy_all: 'eis.policy.*',
  eis_policy_view: 'eis.policy.view',
  eis_policy_write: 'eis.policy.write',
  eis_policy_delete: 'eis.policy.delete',
  eis_claims_all: 'eis.claims.*',
  eis_claims_view: 'eis.claims.view',
  eis_claims_write: 'eis.claims.write',
  eis_claims_edit: 'eis.claims.edit',
  eis_claims_delete: 'eis.claims.delete',
  eis_settings_all: 'eis.settings.*',
  eis_settings_view: 'eis.settings.view',
  eis_settings_write: 'eis.settings.write',
  eis_settings_delete: 'eis.settings.delete',
  eis_products_all: 'eis.products.*',
  eis_products_view: 'eis.products.view',
  eis_products_write: 'eis.products.write',
  eis_products_delete: 'eis.products.delete',
  eis_leads_all: 'eis.leads.*',
  eis_leads_view: 'eis.leads.view',
  eis_leads_write: 'eis.leads.write',
  eis_leads_delete: 'eis.leads.delete',
  eis_workflows_all: 'eis.workflows.*',
  eis_workflows_view: 'eis.workflows.view',
  eis_workflows_write: 'eis.workflows.write',
  eis_workflows_delete: 'eis.workflows.delete',
  // This permission is used to hide pages for all roles except superadmin
  gdv_all: 'gdv.*',
  gdv_view_dot: 'gdv.view.',
  gdv_view: 'gdv.view',
  demo_all: 'demo.*',
  demo_view: 'demo.view',
  brokers_association: 'brokers.association.*',
  brokers_marketing_agent: 'brokers.marketingAgent.*',
  brokers_broker: 'brokers.broker.*',
  workflows_workflow_write: 'workflows.workflow.write',
  feature_beta: 'feature.beta.*',
};
