import React, { ReactElement } from 'react';
import { AppFieldChildrenProps, AppFieldMetaProps } from '../base';
import FieldSelect, { ListValueType } from '../field-types/select';
import { useGenderSelectorOptions } from 'App/model/select-options';

const AppFieldSelect = ( props: AppFieldChildrenProps<ListValueType> ): ReactElement | null => {
  const { ui } = props;
  const genderOptions = useGenderSelectorOptions();

  const configuration = React.useMemo( () => {
    const result = {
      isSearchable: false,
      isClearable: false,
      options: genderOptions.items,
    };
    return result;
  }, [ genderOptions ] );

  const uiWithConfig: AppFieldMetaProps = {
    ...ui,
    toStringCallback: genderOptions.toStringCallback,
    configuration: configuration,
  };

  return (
    <FieldSelect { ...props } ui={ uiWithConfig } />
  );
};

export default AppFieldSelect;
