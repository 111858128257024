import React from 'react';
import { IApiService, PagingRequest } from './base';
import { ListItems } from './api';
import { ListPartnersRequest, ListTagsRequest, GetPartnerRequest } from './partners/requests';
import { Partner, Tag } from './partners/interfaces';

type AllowedExpands = 'partnerType' | 'tags';

export interface IPartnerService {
  listPartners(
    pageParams: PagingRequest,
    searchValue?: string,
    expand?: AllowedExpands[],
  ): Promise<ListItems<Partner>>;

  getPartner(
    code: string,
    expand?: AllowedExpands[],
  ): Promise<Partner>;

  listTags(
    pageParams: PagingRequest,
  ): Promise<ListItems<Tag>>;
}

export class PartnerService implements IPartnerService {
  protected api: IApiService;

  constructor( api: IApiService ) {
    this.api = api;
  }

  async listPartners(
    pageParams: PagingRequest,
    searchValue?: string,
    expand?: AllowedExpands[],
  ): Promise<ListItems<Partner>> {
    const response = await this.api.request( new ListPartnersRequest( pageParams, searchValue, expand ) );

    return response;
  }

  async getPartner(
    code: string,
    expand?: AllowedExpands[],
  ): Promise<Partner> {
    const response = await this.api.request( new GetPartnerRequest( code, expand ) );

    return response.partner;
  }

  async listTags(
    pageParams: PagingRequest,
  ): Promise<ListItems<Tag>> {
    const response = await this.api.request( new ListTagsRequest( pageParams ) );

    return response;
  }
}

export const PartnerServiceContext: React.Context<IPartnerService> = React.createContext( undefined as any );

export const usePartnerService = (): IPartnerService => {
  return React.useContext( PartnerServiceContext );
};
