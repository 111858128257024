import React, { ReactElement } from 'react';
import { AppFieldChildrenProps } from '../base';
import { TwitterPicker, ColorResult } from 'react-color';

const AppFieldColorPicker = ( props: AppFieldChildrenProps<string> ): ReactElement | null => {
  const { ui, config, field, actions, readOnlyControlId } = props;
  const configuration = ui.configuration || {};

  if ( config.editingDisabled ) {
    return (
      <div id={ readOnlyControlId } className="app-field-plaintext">
        <span className="color-view" style={ { backgroundColor: `${props.field.value}` } }>
        </span>
        { props.field.value }
      </div>
    );
  }

  const onChangeHandler = ( color: ColorResult ): void => {
    actions.setFieldValue( field.name, color.hex );
  };

  const onBlurHandler = (): void => {
    actions.setFieldTouched( field.name );
  };

  // Twitter Picker don't allow to detect when we have focus on it and when onBlur is hapening.

  // When using custom widgets that don't have its own input field with name
  // Custom widget also don't generate onBlur event

  return (
    <div tabIndex={ -1 } onBlur={ onBlurHandler }>
      <input type="hidden" name={ field.name } value={ field.value } />
      <TwitterPicker
        triangle={ configuration.triangle }
        color={ field.value }
        colors={ configuration.colors }
        onChange={ onChangeHandler }
      />
    </div>
  );
};

export default AppFieldColorPicker;
