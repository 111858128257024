import React, { ReactElement } from 'react';
import { AppFieldChildrenProps } from '../base';
import Select, { OptionsType, OptionTypeBase, ActionMeta, ValueType } from 'react-select';

export type ListValueType = string | number;

export type AppFieldSelectOption<V extends ListValueType = ListValueType> = OptionTypeBase & {
  value: V;
  label: string;
};

export const toSelectorOptions = ( values: string[], dict: Record<string, string> ): AppFieldSelectOption[] => {
  return values.map( ( v ) => {
    const option: AppFieldSelectOption = {
      value: v,
      label: dict[v],
    };
    return option;
  } );
};

const AppFieldSelect = ( props: AppFieldChildrenProps<ListValueType> ): ReactElement | null => {
  const { ui, config, field, actions, controlId, readOnlyControlId } = props;
  const configuration = ui.configuration || {};

  if ( config.editingDisabled ) {
    // search for value to display here
    let optionToDisplay: AppFieldSelectOption<ListValueType> | undefined = undefined;
    if ( configuration['options'] ) {
      const options: OptionsType<AppFieldSelectOption> = configuration['options'];
      optionToDisplay = options.find( ( i ) => i.value === field.value );
    }
    return (
      <span id={ readOnlyControlId } className="app-field-plaintext">{ optionToDisplay?.label }</span>
    );
  }

  const onChangeHandler = ( value: ValueType<AppFieldSelectOption>, { action }: ActionMeta ): void => {
    if ( value && action === 'select-option' ) {
      if ( Array.isArray( value ) ) {
        const values = value.map( ( o: AppFieldSelectOption ) => o.value );
        actions.setFieldValue( field.name, values );
      } else {
        const v = value as AppFieldSelectOption;
        actions.setFieldValue( field.name, v.value );
      }
    }
  };

  // search for default value through available options
  let defaultValue: AppFieldSelectOption<ListValueType> | undefined = undefined;
  if ( configuration['options'] ) {
    const options: OptionsType<AppFieldSelectOption> = configuration['options'];
    if ( field.value ) {
      defaultValue = options.find( ( i ) => i.value === field.value );
    }
  }

  return (
    <Select
      id={ controlId }
      className={ `app-field-select state-${ props.state }` }
      classNamePrefix={ 'af-select' }
      isDisabled={ config.editingDisabled || ( configuration['isDisabled'] && configuration['isDisabled'] ) }
      isLoading={ false }
      isClearable={ true }
      isSearchable={ true }
      value={ defaultValue }
      name={ field.name }
      placeholder= { ui.description }
      onChange={ onChangeHandler }
      onBlur={ field.onBlur }
      { ...configuration }
      isMulti={ false } // This component does not support multi selection for now
    />
  );
};

export default AppFieldSelect;
