import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { AppFieldChildrenProps } from '../base';
import dayjs from 'dayjs';
import { defaultDateFormat } from 'App/ui-utils';

const AppFieldText = ( props: AppFieldChildrenProps<string> ): ReactElement | null => {
  const { ui, config, meta, field, readOnlyControlId } = props;
  const configuration = ui.configuration || {};
  if ( config.editingDisabled ) {
    return (
      <span id={ readOnlyControlId } className="app-field-plaintext">
        { dayjs.isDayjs( field.value ) ? field.value.format( defaultDateFormat ) : field.value }
      </span>
    );
  }

  return (
    <Form.Control
      type="text"
      placeholder={ ui.description }
      plaintext={ config.editingDisabled }
      readOnly={ config.editingDisabled || ( configuration['isDisabled'] && configuration['isDisabled'] ) }
      isInvalid={ meta.touched && !!meta.error }
      { ...field }
    />
  );
};

export default AppFieldText;
