import React from 'react';
import { PagingRequest, IApiService, ApiResponse } from './base';
import { Logger } from 'loglevel';
import { IApiListResponse } from './policies/new/service';
import {
  GetWFRequest,
  ListWFRequest,
  ListWFVersionRequest,
  GetWFVersionRequest,
  CancelInstanceRequest,
  PatchWFRequest,
  CreateWFRequest,
  DefineServiceEventRequest,
  ListWFInstancesRequest,
  GetWFInstanceVariables,
  RetryInstanceRequest,
  CreateDecisionTableRequest,
  GetDecisionTableRequest,
  ListDecisionTableRequest,
  UpdateDecisionTableRequest,
  PatchDecisionTableRequest,
  GetDecisionTableVersionRequest,
  ListDecisionTableVersionRequest,
} from './workflow-monitor/requests';
import {
  IWFItem,
  IWFInstanceItem,
  IWFInstance,
  IWFVersion,
  InstanceIdType,
  InstanceStatusType,
  IWFPatchRequest,
  ICreateWorkflowRequest,
  IServiceEventRequest,
  IServiceEvent,
  IWFMonitorVersionResponse,
  ITimeRange,
  IWFInstanceVariablesResponse,
  ICreateDecisionTableRequest,
  IUpdateDecisionTableRequest,
  IPatchDecisionTableRequest,
  IDecisionTableVersion,
  IDecisionTable,
} from './workflow-monitor/interfaces';
import { WorkflowInstanceStatus, WorkflowInstanceStatusUnselected } from './workflow-monitor/enums';
import { GetWFInstanceRequest } from './workflow-monitor/requests';
import { apiDateFilterFormat } from 'App/ui-utils';
import ZeebeWorkflowParser from './zeebe-workflow-parser';

export interface IWFMonitorService {
  getWFMonitorItems(
    searchValue: string, paging: PagingRequest, expand?: string[]
  ): Promise<IApiListResponse<IWFItem>>;
  getWFMonitor( workFlowCode: string, expand?: string[] ): Promise<IWFItem>;
  getWFVersion( workFlowCode: string, expand?: string[], includeTasks?: boolean ): Promise<IWFVersion>;
  getWFVersions( paging: PagingRequest, expand?: string[] )
  : Promise<IApiListResponse<IWFVersion>>;
  getWFMonitorVersions( workflowCode: string, workFlowVersionCode: string, paging: PagingRequest, )
  : Promise<IWFMonitorVersionResponse>;
  getWFMonitorInstances(
    searchValue: string,
    worfflowCode: string,
    workFlowVersionId: number | null,
    versionNumber: number | null,
    status: string[],
    paging: PagingRequest,
    timeRange?: ITimeRange,
    expand?: string[],
    workflowId?: number,
    entityCode?: string,
  ): Promise<IApiListResponse<IWFInstanceItem>>;
  cancelInstance( instanceCode: string ):Promise<ApiResponse>;
  retryInstance( instanceCode: string ):Promise<ApiResponse>;
  actionOnWFMonitorInstances( instances: InstanceIdType[], newState: WorkflowInstanceStatus ):
  Promise<ApiResponse>;
  getWFMonitorInstance( workFlowId: number ): Promise<IWFInstanceItem>;
  getInstanceDetails( instanceCode: string, expand?: string[] ): Promise<IWFInstance>;
  patchWorkflow( workflow: IWFPatchRequest ): Promise<IWFItem>;
  createWorkflow( data: ICreateWorkflowRequest ): Promise<IWFItem>;
  defineServiceEvent( data: IServiceEventRequest ): Promise<IServiceEvent>;
  getInstanceVariables( workflowCode: string, taskKey: string ): Promise<IWFInstanceVariablesResponse>;
  createDecisionTable( data: ICreateDecisionTableRequest ): Promise<IDecisionTable>;
  getDecisionTable( code: string, expand?: string[] ): Promise<IDecisionTable>;
  listDecisionTables( searchValue: string, paging: PagingRequest, expand?: string[] )
  : Promise<IApiListResponse<IDecisionTable>>;
  updateDecisionTable( data: IUpdateDecisionTableRequest ): Promise<IDecisionTable>;
  patchDecisionTable( data: IPatchDecisionTableRequest ): Promise<IDecisionTable>;
  getDecisionTableVersion( code: string, version: number ): Promise<IDecisionTableVersion>;
  listDecisionTableVersions( decisionTableCode: string, paging: PagingRequest, searchValue?: string )
  : Promise<IApiListResponse<IDecisionTableVersion>>;
  deleteDecisionTable( code: string ): Promise<void>;
}

export class WFMonitorService implements IWFMonitorService {
  protected api: IApiService;
  protected logger: Logger;

  constructor( api: IApiService, logger: Logger ) {
    this.api = api;
    this.logger = logger;
  }

  getWFVersion( versionCode: string, expand?: string[], includeTasks?: boolean ): Promise<IWFVersion> {
    return this.api.request( new GetWFVersionRequest( versionCode ) )
      .then( async ( response ) => {
        const { version } = response;
        if ( !version ) {
          throw new Error( `Can't load, versionWorkFlowCode: ${versionCode}` );
        }

        if ( includeTasks ) {
          const definitionJson = await ZeebeWorkflowParser.parseDefinition( version.definition );

          version.tasks = ZeebeWorkflowParser.getTasksFromDefinition( definitionJson );
          version.sequences = ZeebeWorkflowParser.getSequencesDefinition( definitionJson );
        }

        return version;
      } );
  }

  getWFVersions( paging: PagingRequest, expand?: string[] )
    : Promise<IApiListResponse<IWFVersion>> {
    return this.api.request( new ListWFVersionRequest( paging, expand ) )
      .then( ( response ) => {
        if ( !response.items ) {
          throw new Error( `Can't load workflowVersions for workflow with id: ${paging.filter}` );
        }
        return response;
      } );
  }

  getWFMonitorItems(
    searchValue: string, paging: PagingRequest, expand?: string[],
  ): Promise<IApiListResponse<IWFItem>> {
    return this.api.request( new ListWFRequest( searchValue, paging, expand ) )
      .then( ( response ) => {
        let workflows: IWFItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          workflows = items.map( ( item, index ) => {
            const workflow: IWFItem = {
              ...item,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
            };

            return workflow;
          } );
        }

        const res: IApiListResponse<IWFItem> = {
          items: workflows,
          nextPageToken: workflows ? workflows.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  getWFMonitor( workFlowCode: string, expand?: string[] ): Promise<IWFItem> {
    return this.api.request( new GetWFRequest( workFlowCode, expand ) )
      .then( ( response ) => {
        const { workflow } = response;
        if ( workflow === undefined ) {
          throw new Error( `Can't load workFlowCode: ${workFlowCode}` );
        }

        const version = workflow && workflow.versions ? workflow.versions : null;

        let workflowData: IWFItem = { ...workflow };
        if ( version === null ) {
          workflowData = {
            ...workflow,
            versions: [],
          };
        }

        return workflowData;
      } );
  }

  patchWorkflow( patchData: IWFPatchRequest ): Promise<IWFItem> {
    return this.api.request( new PatchWFRequest( patchData ) )
      .then( ( response ) => {
        const { workflow } = response;
        if ( workflow === undefined ) {
          throw new Error( `Can't patch, workFlowID: ${patchData.id}` );
        }

        return workflow;
      } );
  }

  createWorkflow( data: ICreateWorkflowRequest ): Promise<IWFItem> {
    return this.api.request( new CreateWFRequest( data ) )
      .then( ( response ) => {
        const { workflow } = response;
        if ( workflow === undefined ) {
          throw new Error( `Can't create, workflow: ${data.name}` );
        }

        return workflow;
      } );
  }

  defineServiceEvent( data: IServiceEventRequest ): Promise<IServiceEvent> {
    return this.api.request( new DefineServiceEventRequest( data ) )
      .then( ( response ) => {
        const { serviceEvent } = response;
        if ( serviceEvent === undefined ) {
          throw new Error( `Can't define service event, workflowId: ${data.workflowId}` );
        }

        return serviceEvent;
      } );
  }

  getWFMonitorVersions(
    workflowCode: string,
    workFlowVersionCode: string,
    paging: PagingRequest,
  ): Promise<IWFMonitorVersionResponse> {
    paging.filter = [ { id: 'code', value: workFlowVersionCode } ];
    return this.api.request( new ListWFVersionRequest( paging ) )
      .then( ( response ) => {
        const { items:[ version ] } = response;
        if ( version === undefined ) {
          throw new Error( `Can't load, workFlowVersionCode: ${workFlowVersionCode}` );
        }

        return {
          id: version.id,
          workflowCode,
          versionNumber: version.versionNumber,
        };
      } );
  }

  getWFMonitorInstances(
    searchValue: string,
    workflowCode: string,
    workFlowVersionId: number | null,
    versionNumber: number | null,
    status: string[],
    paging: PagingRequest,
    timeRange?: ITimeRange,
    expand?: string[],
    workflowId?: number,
    entityCode?: string,
  ): Promise<IApiListResponse<IWFInstanceItem>> {
    paging.filter = [];

    if ( workFlowVersionId ) {
      paging.filter.push(
        { id: 'workflowVersionId', value: String( workFlowVersionId ) },
      );
    }

    if ( workflowId ) {
      paging.filter.push(
        { id: 'workflowId', value: String( workflowId ) },
      );
    }

    if( entityCode ) {
      paging.filter.push(
        { id: 'entityCode', value: String ( entityCode ) },
      );
    }

    if ( timeRange ) {
      const { startDate, endDate } = timeRange;
      paging.filter.push( { id: 'createdAt', value: startDate.format( apiDateFilterFormat ), operator: '>=' } );
      paging.filter.push( { id: 'createdAt', value: endDate.format( apiDateFilterFormat ), operator: '<=' } );
    }

    if ( status.length ) {
      if( status.includes( WorkflowInstanceStatusUnselected.Unselected ) ) {
        return new Promise( ( resolve ) => {
          setTimeout( () => {
            resolve( 'foo' );
          }, 300 );
        } ).then( () => {
          return {
            items: [],
            nextPageToken: '1',
          };
        } );
      }
      paging.filter.push( { id: 'status', value: status.join( ',' ) } );
    }

    return this.api.request( new ListWFInstancesRequest( searchValue, paging, expand ) )
      .then( ( response ) => {
        let instances: IWFInstanceItem[] = [];
        const { items } = response;

        if ( items && items.length >= 0 ) {
          instances = items.map( ( item ) => {
            const instance: IWFInstanceItem = {
              ...item,
              workflowName: item.workflow?.name || '',
              workflowCode: item.workflow?.code || workflowCode,
              versionNumber: item.workflowVersion?.versionNumber || versionNumber || 1,
              createdAt: new Date( item.createdAt ),
              updatedAt: new Date( item.updatedAt ),
            };

            return instance;
          } );
        }

        const res: IApiListResponse<IWFInstanceItem> = {
          items: instances,
          nextPageToken: response.nextPageToken,
        };

        return res;
      } );
  }

  cancelInstance( instanceCode: string ): Promise<ApiResponse> {
    return this.api.request( new CancelInstanceRequest( instanceCode ) )
      .then( ( response ) => {
        return response;
      } );
  }

  retryInstance( instanceCode: string ): Promise<ApiResponse> {
    return this.api.request( new RetryInstanceRequest( instanceCode ) )
      .then( ( response ) => {
        return response;
      } );
  }

  actionOnWFMonitorInstances( instances: InstanceIdType[], newState: WorkflowInstanceStatus ):
  Promise<ApiResponse> {
    throw new Error( 'Method retryWFInstances not implemented.' );
  }

  getWFMonitorInstance( workFlowId: number ): Promise<IWFInstanceItem> {
    throw new Error( 'Method getWFInstance not implemented.' );
  }

  getWFMonitorInstancesByCode( workFlowCode: string, paging: PagingRequest,
    statusFilter: InstanceStatusType[] ): Promise<IApiListResponse<IWFInstance>>{
    throw new Error( 'Method getWFInstancesbyId not implemented.' );
  }

  getInstanceDetails( instanceCode: string, expand?: string[] ): Promise<IWFInstance> {
    return this.api.request( new GetWFInstanceRequest( instanceCode, expand ) )
      .then( ( response ) => {
        const { instance } = response;
        if ( instance === undefined ) {
          throw new Error( `Can't load instanceCode: ${instanceCode}` );
        }

        return instance;
      } );
  }

  getInstanceVariables( workflowCode: string, taskKey: string ): Promise<IWFInstanceVariablesResponse> {
    return this.api.request( new GetWFInstanceVariables( workflowCode, taskKey ) )
      .then( ( response ) => {
        return response;
      } );
  }

  createDecisionTable( data: ICreateDecisionTableRequest ): Promise<IDecisionTable> {
    return this.api.request( new CreateDecisionTableRequest( data ) )
      .then( ( response ) => {
        const { decisionTable } = response;
        if ( decisionTable === undefined ) {
          throw new Error( `Can't create, decision table: ${data.name}` );
        }

        return decisionTable;
      } );
  }

  getDecisionTable( code: string, expand?: string[] ): Promise<IDecisionTable> {
    return this.api.request( new GetDecisionTableRequest( code, expand ) )
      .then( ( response ) => {
        const { decisionTable } = response;
        if ( decisionTable === undefined ) {
          throw new Error( `Can't load decision table: ${code}` );
        }

        const versions = decisionTable && decisionTable.versions ? decisionTable.versions : null;

        let decisionData: IDecisionTable = { ...decisionTable };
        if ( versions === null ) {
          decisionData = {
            ...decisionTable,
            versions: [],
          };
        }

        return decisionData;
      } );
  }

  listDecisionTables( searchValue: string, paging: PagingRequest, expand?: string[] )
    : Promise<IApiListResponse<IDecisionTable>> {
    return this.api.request( new ListDecisionTableRequest( searchValue, paging, expand ) )
      .then( ( response ) => {
        let tables: IDecisionTable[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          tables = items.map( ( item, index ) => {
            const decision: IDecisionTable = {
              ...item,
              createdAt: new Date( item.createdAt! as string ),
              updatedAt: new Date( item.updatedAt! as string ),
            };

            return decision;
          } );
        }

        const res: IApiListResponse<IDecisionTable> = {
          items: tables,
          nextPageToken: tables ? tables.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  updateDecisionTable( decisionTable: IUpdateDecisionTableRequest ): Promise<IDecisionTable> {
    return this.api.request( new UpdateDecisionTableRequest( decisionTable ) )
      .then( ( { decisionTable: updatedDecisionTable } ) => {
        if ( !updatedDecisionTable ){
          throw new Error( `Can't load decision table: ${decisionTable.code}` );
        }

        return updatedDecisionTable;
      } );
  }

  patchDecisionTable( decisionTable: IPatchDecisionTableRequest ): Promise<IDecisionTable> {
    return this.api.request( new PatchDecisionTableRequest( decisionTable ) )
      .then( ( { decisionTable: updatedDecisionTable } ) => {
        if ( !updatedDecisionTable ){
          throw new Error( `Can't load decision table: ${decisionTable.code}` );
        }

        return updatedDecisionTable;
      } );
  }

  getDecisionTableVersion( code: string, version: number ): Promise<IDecisionTableVersion> {
    return this.api.request( new GetDecisionTableVersionRequest( code, version ) )
      .then( ( response ) => {
        const { decisionTableVersion } = response;
        if ( decisionTableVersion === undefined ) {
          throw new Error( `Can't load decision table version: ${code}` );
        }

        return decisionTableVersion;
      } );
  }

  listDecisionTableVersions( decisionTableCode: string, paging: PagingRequest, searchValue = '' )
    : Promise<IApiListResponse<IDecisionTableVersion>> {
    return this.api.request( new ListDecisionTableVersionRequest( decisionTableCode, paging, searchValue ) )
      .then( ( response ) => {
        let decisions: IDecisionTableVersion[] = [];
        const { items, nextPageToken } = response;

        const res: IApiListResponse<IDecisionTableVersion> = {
          items,
          nextPageToken: decisions ? decisions.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  deleteDecisionTable( code: string ): Promise<void> {
    throw new Error( 'Method deleteDecisionTable not implemented.' );
  }
};

export const WFMonitorServiceContext: React.Context<IWFMonitorService> = React.createContext( undefined as any );

export const useWFMonitorService = (): IWFMonitorService => {
  return React.useContext( WFMonitorServiceContext );
};
