import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { AppFieldChildrenProps } from '../base';

const AppFieldNumber = ( props: AppFieldChildrenProps ): ReactElement | null => {
  const { ui, config, meta, field, readOnlyControlId } = props;
  const configuration = ui.configuration || {};
  if ( config.editingDisabled ) {
    return (
      <span id={ readOnlyControlId } className="app-field-plaintext">
        { field.value }
      </span>
    );
  }

  return (
    <Form.Control
      type="number"
      placeholder={ ui.description }
      plaintext={ config.editingDisabled }
      readOnly={ config.editingDisabled || ( configuration['isDisabled'] && configuration['isDisabled'] ) }
      isInvalid={ meta.touched && !!meta.error }
      { ...field }
    />
  );
};

export default AppFieldNumber;


