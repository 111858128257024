import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { AppFieldChildrenProps } from '../base';

const AppFieldPassword = ( props: AppFieldChildrenProps<string> ): ReactElement | null => {
  const { ui, config, meta, field } = props;

  return (
    <Form.Control
      type="password"
      placeholder={ ui.description }
      plaintext={ config.editingDisabled }
      readOnly={ config.editingDisabled }
      isInvalid={ meta.touched && !!meta.error }
      { ...field }
    />
  );
};

export default AppFieldPassword;
