import React from 'react';
import { useTranslation } from 'react-i18next';
import { IUserDetailsItem } from 'Services/users/user-interfaces';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useProfileAvatar } from '../utils/hooks';
import { Placement } from 'react-bootstrap/Overlay';
import { useEntityInfoHook } from './hooks/EntityInfoHook';

export interface UserInfoTooltipParams {
  user?: IUserDetailsItem;
  placement?: Placement;
}

export const UserInfoTooltip : React.FC<UserInfoTooltipParams> = ( { placement, user } ) => {
  const { t } = useTranslation( [ 'base' ] );
  const utils = useEntityInfoHook();
  const avatar = useProfileAvatar( user?.email || '' );
  const fullName = utils.getFullUserName( user );

  return (
    <OverlayTrigger
      placement={ placement || 'bottom-end' }
      trigger="hover"
      overlay={
        <Tooltip
          className="show user-info-tooltip"
          id={ `user-info-tooltip-${user?.id || ''}` }
        >
          <Row className="m-0 p-0">
            <Col className="avatar-box m-0 pl-0">
              <img
                className="avatar img-radius"
                src={ avatar }
                alt={ fullName }
              />
            </Col>
            <Col className="content-box m-0 p-0">
              <h6 className="p-0 m-0">{ fullName }</h6>
              <p className="p-0 m-0">{ user && user.email }</p>
              { user?.organization && (
                <p className="p-0 m-0">
                  { `${t( 'infobox.organization' )} ${user.organization.name}` }
                </p>
              ) }
            </Col>
          </Row>
        </Tooltip>
      }
    >
      <span className="entity-user">{ fullName }</span>
    </OverlayTrigger>
  );
};

export default UserInfoTooltip;
