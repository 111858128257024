import React from 'react';
import { apiDateTimeFormat, defaultDateTimeFormat } from 'App/ui-utils';
import dayjs from 'dayjs';
import UserInfoTooltip from './UserInfoTooltip';
import { IIdentityTrackableEntity } from 'Services/base/interfaces';
import { Placement } from 'react-bootstrap/Overlay';

export interface IEntityInfoCellParams {
  entity?: IIdentityTrackableEntity;
  placement?: Placement;
}

export const EntityInfoCell : React.FC<IEntityInfoCellParams> = ( { entity, placement } ) => {
  // Some entities do not have updatedAt field
  // For example, if an entity is only created and never updated
  const date = entity?.updatedAt || entity?.createdAt;
  const user = entity?.cashedUser;

  return (
    <span className="entity-info-cell">
      { date && (
        dayjs( date, { format: apiDateTimeFormat } ).format( defaultDateTimeFormat )
      ) }
      { date && user && ( <>&nbsp;/&nbsp;</> ) }
      { user && (
        <UserInfoTooltip placement={ placement || 'auto' } user={ user } />
      ) }
    </span>
  );
};

export default EntityInfoCell;
