import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { AppFieldChildrenProps } from '../base';

const AppFieldBoolean = ( props: AppFieldChildrenProps<boolean> ): ReactElement | null => {

  const { ui, config, field, state, meta } = props;
  const configuration = ui.configuration || {};
  // isChecked = false is required for undefined values. So if value is undefined it will set to false
  const { value: isChecked = false, ...rest } = field;

  return (
    <div className="checkbox pt-0 pb-0">
      <Form.Control
        type="checkbox"
        className={ `form-control-checkbox state-${state}` }
        checked={ isChecked }
        disabled={ config.editingDisabled || ( configuration['isDisabled'] && configuration['isDisabled'] ) }
        readOnly={ config.editingDisabled }
        isInvalid={ meta.touched && !!meta.error }
        { ...rest }
      />
      <Form.Label className={ 'cr mt-2' } aria-label={ ui.title } />
    </div>
  );
};

export default AppFieldBoolean;
