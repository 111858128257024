import React, { ReactElement } from 'react';
import { AppFieldChildrenProps } from '../base';
import AsyncSelect from 'react-select/async';
import { OptionsType, ActionMeta, ValueType } from 'react-select';
import { AppFieldSelectOption, ListValueType } from './select';

const AppFieldAsyncSelect = ( props: AppFieldChildrenProps<ListValueType> ): ReactElement | null => {
  const { ui, config, field, actions, controlId, readOnlyControlId } = props;
  const configuration = ui.configuration || {};
  const options: OptionsType<AppFieldSelectOption> = configuration['options'] || [];

  const loadOptions = React.useCallback( ( inputValue: string ) => {
    return new Promise<AppFieldSelectOption<ListValueType>[]>( ( resolve ) => {
      const filteredOption = options.filter( ( option ) => {
        return option.label.toLowerCase().includes( inputValue.toLowerCase() );
      } ).slice( 0, 10 );
      resolve( filteredOption );
    } );
  }, [ options ] );

  const defaultOptions = React.useMemo( () => {
    return options.slice( 0, 10 );
  }, [ options ] );

  const onChangeHandler = React.useCallback(
    ( value: ValueType<AppFieldSelectOption>, { action }: ActionMeta ): void => {
      if ( value && action === 'select-option' ) {
        if ( Array.isArray( value ) ) {
          const values = value.map( ( o: AppFieldSelectOption ) => o.value );
          actions.setFieldValue( field.name, values );
        } else {
          const v = value as AppFieldSelectOption;
          actions.setFieldValue( field.name, v.value );
        }
      }
    }, [ actions, field.name ] );

  const selectedOption = options.find( ( i ) => i.value === field.value );
  if ( config.editingDisabled ) {
    return (
      <span id={ readOnlyControlId } className="app-field-plaintext">{ selectedOption?.label }</span>
    );
  }

  return (
    <AsyncSelect
      id={ controlId }
      className={ `app-field-select state-${ props.state }` }
      classNamePrefix={ 'af-select' }
      isDisabled={ config.editingDisabled }
      name={ field.name }
      placeholder= { ui.description }
      onChange={ onChangeHandler }
      onBlur={ field.onBlur }
      isMulti={ false }
      value={ selectedOption }
      loadOptions={ loadOptions }
      defaultOptions={ defaultOptions }
    />
  );
};

export default AppFieldAsyncSelect;
