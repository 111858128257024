import { Partner, Tag, PartnerResponse } from './interfaces';
import { BaseRequest, PagingRequest } from 'Services/base';
import { getQueryParamsForListAPI, ListItems } from 'Services/api';

export class ListPartnersRequest extends BaseRequest<ListItems<Partner>> {
  constructor(
    pageParameters: PagingRequest,
    searchValue?: string,
    expand?: string[],
  ) {
    const queryParams = getQueryParamsForListAPI( pageParameters, searchValue, 'search' );

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: '/partnerservice/v1/partners',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class GetPartnerRequest extends BaseRequest<PartnerResponse> {
  constructor( code: string, expand?: string[] ) {

    super( {
      method: 'GET',
      url: `/partnerservice/v1/partners/${code}${ expand?.length ? `?expand=${expand.join()}` : '' }`,
      responseType: 'json',
    } );
  }
}

export class ListTagsRequest extends BaseRequest<ListItems<Tag>> {
  constructor( pageParameters: PagingRequest ) {
    const queryParams = getQueryParamsForListAPI( pageParameters );

    super( {
      method: 'GET',
      url: '/partnerservice/v1/tags',
      responseType: 'json',
      params: queryParams,
    } );
  }
}
